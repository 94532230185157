/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getValues } from "../../reducers/loginReducer";
import { calificacionesAreasGet } from "../../services/calificaciones";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Calificaciones",
    },
  },
};

export default function Index() {
  const { token } = useSelector(getValues);
  const [data, setData] = React.useState([]);
  const [optiones, setoptiones] = useState([]);

  React.useEffect(() => {
    calificacionesAreasGet(token).then((res) => {
      setData([]);
      let labels = [];
      let calificaciones = [];
      let values = [];
      res.data.forEach((item) => {
        optiones.push({
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: item.area,
            },
          },
        });

        setoptiones(optiones);
        item.calificaciones.forEach((item2) => {
          const promedio = item2.critero.reduce((a, b) => {
            if (b.area_id === item.area_id) {
              return a + b.calificacion;
            }
          }, 0);

          item2.critero.forEach((element) => {
            if (element.area_id === item.area_id) {
              labels.push(element.empleado);
            }
          });
          labels = [...new Set(labels)];

          if (promedio) {
            calificaciones.push(promedio);
          }
        });

        values.push({
          labels: labels,
          data: calificaciones,
        });

        labels = [];
        calificaciones = [];
      });

      const newArray = values.map((item) => {
        return {
          labels: item.labels,
          datasets: [
            {
              fill: true,
              label: "Calificaciones",
              data: item.data,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ],
        };
      });
      setData(newArray);
    });

    return () => {};
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Dashboard</h1>

          <div className="row">
            {data.map((item, index) => {
              return (
                <div className="col-md-12" key={index}>
                  <Bar data={item} options={optiones[index]} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
