import axios from "axios";
const APP_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

export const empleadosGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}empleadosGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const empleadosAreaGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}empleadosAreaGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const empleadosAdd = async (data, token) => {
  try {
    const res = await axios.post(
      `${APP_URL}empleadosAdd`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const empleadosUpdate = async (data, token) => {
  try {
    const res = await axios.post(
      `${APP_URL}empleadosUpdate`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const empleadosDelete = async (data, token) => {
  try {
    const res = await axios.post(
      `${APP_URL}empleadosDelete`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const empleadoAddContrato = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}empleadoAddContrato`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};
