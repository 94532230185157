import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Input from "../../../components/Form/Input";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Transition = React.forwardRef(function Transition(props , ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalUpdate({ open, setOpen, saveInfo, data, Areas }) {
  const [values, setyvalues] = React.useState({
    id: data.id,
    nombre: data.nombre,
    correo: data.correo,
    telefono: data.telefono,
    areas_id: data.areas_id,
    direccion: data.direccion,
    fecha_inicio: data.fecha_inicio,
  });

  React.useEffect(() => {
    if(data){
      setyvalues({
        id: data.id,
        nombre: data.nombre,
        correo: data.correo,
        telefono: data.telefono,
        areas_id: data.areas_id,
        direccion: data.direccion,
        fecha_inicio: data.fecha_inicio,
      })
    }
    return () => {
    }
  }, [data])
  

  const handleCloseUpdate = () => {
    cleanValues();
    setOpen(false);
  };

  const save = () => {
    const arraySend = {
      id: values.id,
      nombre: values.nombre,
      correo: values.correo,
      telefono: values.telefono,
      areas_id: values.areas_id,
      direccion: values.direccion,
      fecha_inicio: values.fecha_inicio,
    };

    saveInfo(arraySend);
  };

  const cleanValues = () => {
    setyvalues({
      nombre: "",
    });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCloseUpdate}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{
            background: "#fff",
            padding: "0px !important",
          }}
        >
          <Toolbar
            style={{
              background: "#363335",
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseUpdate}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Actualizar area
            </Typography>
            <Button autoFocus color="inherit" onClick={save}>
              Actualizar
            </Button>
          </Toolbar>
          <div className="px-5 pb-5 mt-3">
          <Input
              label="Nombre"
              name="name"
              value={values.nombre}
              onChange={(e) => {
                setyvalues({ ...values, nombre: e.target.value });
              }}
            />
            <Input
              label="Correo"
              name="name"
              value={values.correo}
              onChange={(e) => {
                setyvalues({ ...values, correo: e.target.value });
              }}
            />
            <Input
              label="Telefono"
              name="name"
              value={values.telefono}
              onChange={(e) => {
                setyvalues({ ...values, telefono: e.target.value });
              }}
            />
            <Input
              label="Direccion"
              name="name"
              value={values.direccion}
              onChange={(e) => {
                setyvalues({ ...values, direccion: e.target.value });
              }}
            />
            <Input
              label="Fecha de inicio"
              name="name"
              value={values.fecha_inicio}
              onChange={(e) => {
                setyvalues({ ...values, fecha_inicio: e.target.value });
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Selecciona un area
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.areas_id}
                label="Selecciona un area"
                onChange={(e) => {
                  setyvalues({ ...values, areas_id: e.target.value });
                }}
              >
                {Areas.map((item) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        </AppBar>
      </Dialog>
    </div>
  );
}
