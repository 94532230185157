import axios from "axios";
const APP_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

export const areasGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}areasGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const areasAdd = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}areasAdd`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const areasUpdate = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}areasUpdate`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const areasDelete = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}areasDelete`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};
