/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  calificacionesGet,
  calificacionesAdd,
  calificacionesUpdate,
  calificacionesDelete,
} from "../../services/calificaciones";
import { getValues } from "../../reducers/loginReducer";
import TableComponent from "../../components/Table/calificaciones";
import ModalAdd from "./Modals/ModalAdd";
import { toast } from "react-hot-toast";
import ModalUpdate from "./Modals/ModalUpdate";
import {
  calificacionesCriteriosGet,
  calificacionesEmpleadosGet,
} from "../../services/calificaciones";

const columns = ["", "Empleado"];
const dataGet = ["key", "empleado"];

export default function Index() {
  const dispatch = useDispatch();
  const { token } = useSelector(getValues);
  const [Data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setopenUpdate] = React.useState(false);
  const [dataUpdate, setDataUpdate] = React.useState({});
  const [Criterios, setCriterios] = React.useState([]);
  const [Empleados, setEmpleados] = React.useState([]);

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(setTitle("Descargando calificaciones"));
    dispatch(showLoader());
    getInfo();
    return () => {};
  }, []);

  useEffect(() => {
    calificacionesCriteriosGet(token).then((res) => {
      setCriterios(res.data);
    });
  }, []);

  useEffect(() => {
    calificacionesEmpleadosGet(token).then((res) => {
      setEmpleados(res.data);
    });
  }, []);

  const getInfo = async () => {
    const response = await calificacionesGet(token);
    dispatch(hideLoader());
    dispatch(setTitle("Listado de calificaciones"));
    setData(response.data);
  };

  const openModalUpdateData = (data) => {
    setDataUpdate(data);
    setopenUpdate(true);
  };

  const saveInfo = (data) => {
    const save = async () => {
      const info = await calificacionesAdd(data, token);
      if (info?.success === true) {
        setOpen(false);
        toast.success("Empleado guardado con exito");
        getInfo();
      } else {
        toast.error("Error al guardar el Empleado");
      }
    };
    save();
  };

  const updateInfo = (data) => {
    const save = async () => {
      const info = await calificacionesUpdate(data, token);
      if (info?.success === true) {
        setopenUpdate(false);
        toast.success("Empleado actualizado con exito");
        getInfo();
      } else {
        toast.error("Error al actualizar el Empleado");
      }
    };
    save();
  };

  const deleteDato = async (id) => {
    const info = await calificacionesDelete(
      {
        id,
      },
      token
    );
    if (info?.success === true) {
      toast.success("Empleado eliminado con exito");
      getInfo();
    } else {
      toast.error("Error al eliminar el Empleado");
    }
  };

  return (
    <div className="container mt-5">
      {open && (
        <ModalAdd
          Criterios={Criterios}
          Empleados={Empleados}
          open={open}
          setOpen={setOpen}
          saveInfo={saveInfo}
        />
      )}
      {openUpdate && (
        <ModalUpdate
          Criterios={Criterios}
          Empleados={Empleados}
          open={openUpdate}
          setOpen={setopenUpdate}
          saveInfo={updateInfo}
          data={dataUpdate}
          token={token}
          getInfo={getInfo}
        />
      )}
      <TableComponent
        title="Listado de calificaciones"
        data={Data ?? []}
        columns={columns}
        dataGet={dataGet}
        deleteDato={deleteDato}
        openModal={openModal}
        updateData={openModalUpdateData}
      />
    </div>
  );
}
