import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../reducers/loginReducer";
import loaderReducer from "../reducers/loaderReducer";

export default configureStore({
  reducer: {
    loader: loaderReducer,
    login: loginReducer,
  },
});
