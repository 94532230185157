/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.svg";

const urlPaths = [
  { path: "/dashboard", state: "Panel principal", icon: "mdi mdi-speedometer" },
  { path: "/areas", state: "Areas", icon: "mdi mdi-speedometer" },
  {
    path: "/calificaciones",
    state: "Calificaciones",
    icon: "mdi mdi-speedometer",
  },
  { path: "/empleados", state: "Empleados", icon: "mdi mdi-speedometer" },
  { path: "/criterios", state: "Criterios", icon: "mdi mdi-speedometer" },
  { path: "/usuarios", state: "Usuarios", icon: "mdi mdi-speedometer" },
];

export default function Sidebar() {
  const { pathname } = useLocation();
  const [Collapse, setCollapse] = useState(null);

  const CollapseShow = (key) => {
    if (Collapse === key) {
      setCollapse(null);
    } else {
      setCollapse(key);
    }
  };

  const verificarIndex = (path, index) => {
    if (pathname === path) {
      if (Collapse === index) {
        return "active";
      } else {
        return "active";
      }
    } else {
      return "";
    }
  };

  const closeSession = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="d-flex justify-content-center px-5 mt-3 pb-0">
        <img
          className="img-fluid d-none d-lg-block"
          src={Logo}
          alt="Alberi"
          id="logo"
        />
        <img
          width={30}
          className="img-fluid d-sm-block d-md-block d-lg-none"
          src={require("../../assets/logo.svg")}
          alt="Alberi"
          id="logo-mini"
        />
      </div>
      <ul className="nav">
        <li className="nav-item nav-category">
          <span className="nav-link">Barra de navegación</span>
        </li>
        {urlPaths.map((item, index) => (
          <li
            className={`nav-item menu-items ${
              pathname === item.path ? "active" : ""
            }`}
            key={index}
          >
            <Link
              className="nav-link"
              to={item.path}
              style={{ marginLeft: -20 }}
              onClick={() => {
                CollapseShow(index);
              }}
            >
              <div className="nav-link menu-expanded" data-toggle="collapse">
                <span className="menu-icon">
                  <i className={item.icon}></i>
                </span>
                <span className="menu-title">{item.state}</span>
              </div>
            </Link>
            <div className={Collapse == index ? "collapse show" : "collapse"}>
              <ul className="nav flex-column sub-menu">
                {item?.subMenu?.map((item2, index2) => (
                  <li
                    className={`nav-item ${
                      pathname === item2.path
                        ? verificarIndex(item2.path, index)
                        : ""
                    }`}
                  >
                    <Link className="nav-link" to={item2.path}>
                      {item2.state}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
        <button
          className="btn btn-primary btn-lg"
          type="button"
          onClick={closeSession}
        >
          Cerrar sesión
        </button>
      </ul>
    </nav>
  );
}
