import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Input from "../../../components/Form/Input";

const Transition = React.forwardRef(function Transition(props , ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen, saveInfo }) {
  const [values, setyvalues] = React.useState({
    nombre: "",
  });

  const handleClose = () => {
    cleanValues();
    setOpen(false);
  };

  const save = () => {
    const arraySend = {
      nombre: values.nombre,
    };

    saveInfo(arraySend);
  };

  const cleanValues = () => {
    setyvalues({
      nombre: "",
    });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{
            background: "#fff",
            padding: "0px !important",
          }}
        >
          <Toolbar
            style={{
              background: "#363335",
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Nuevo area
            </Typography>
            <Button autoFocus color="inherit" onClick={save}>
              Guardar
            </Button>
          </Toolbar>
          <div className="px-5 mt-3">
            <Input
              label="Nombre"
              name="name"
              value={values.nombre}
              onChange={(e) => {
                setyvalues({ ...values, nombre: e.target.value });
              }}
            />
          </div>
        </AppBar>
      </Dialog>
    </div>
  );
}
