import React from "react";
import "../../style/Loader.scss";

const LoaderFullScreen = ({ hide, title = "Cargando información..." }) => {
  return (
    <div
      className={`loader d-flex flex-col justify-content-center ${
        hide ? "hide" : ""
      }`}
    >
      {!hide && <h3 className="text-white">{title}</h3>}
    </div>
  );
};

export default LoaderFullScreen;
