import { createSlice } from "@reduxjs/toolkit";
import validateToken from '../utils/validateToken';

export const loginReducer = createSlice({
  name: "loader",
  initialState: {
    _id: localStorage.getItem("_tokenAA") ? validateToken.decode(localStorage.getItem("_tokenA"))?.user_id : "",
    username: localStorage.getItem("_tokenA") ? validateToken.decode(localStorage.getItem("_tokenA"))?.username : "",
    email: localStorage.getItem("_tokenA") ? validateToken.decode(localStorage.getItem("_tokenA"))?.user_email : "",
    token: localStorage.getItem("_tokenA") ? validateToken.decode(localStorage.getItem("_tokenA"))?.access_token : "",
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAllValues: (state, action) => {
      state._id = validateToken.encode(action.payload._id);
      state.firstName = action.payload.firstName;
      state.email = action.payload.email;
      state.token = action.payload.token;
      localStorage.setItem("_tokenA", validateToken.encode(action.payload));
    },

    setCleanValues: (state, action) => {
      state._id = "";
      state.firstName = "";
      state.email = "";
      state.token = "";
      localStorage.removeItem("_tokenA");
    }
  },
});

export const {
  setFirstName,
  setEmail,
  setToken,
  setAllValues,
  setCleanValues,
} = loginReducer.actions;

export const getValues = (state) => state.login;

export default loginReducer.reducer;
