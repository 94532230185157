import { TextField } from "@mui/material";
import React from "react";

export default function Input({
  label,
  name,
  value,
  onChange,
  password = false,
  type = "text",
}) {
  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      value={value}
      name={name}
      onChange={onChange}
      className="mb-3"
      type={password ? "password" : type ? type : "text"}
    />
  );
}
