/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch, useSelector } from "react-redux";
import { userGet, userAdd, userUpdate, userDelete } from "../../services/user";
import { getValues } from "../../reducers/loginReducer";
import TableComponent from "../../components/Table";
import ModalAdd from "./Modals/ModalAdd";
import { toast } from "react-hot-toast";
import ModalUpdate from "./Modals/ModalUpdate";

const columns = ["Nombre", "Correo"];
const dataGet = ["name", "email"];
export default function Index() {
  const dispatch = useDispatch();
  const { token } = useSelector(getValues);
  const [Data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setopenUpdate] = React.useState(false);
  const [dataUpdate, setDataUpdate] = React.useState({});

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(setTitle("Descargando usuarios"));
    dispatch(showLoader());
    getInfo();
    return () => {};
  }, []);

  const getInfo = async () => {
    const response = await userGet(token);
    dispatch(hideLoader());
    dispatch(setTitle("Listado de usuarios"));
    setData(response.data);
  };

  const openModalUpdateData = (data) => {
    setDataUpdate(data);
    setopenUpdate(true);
  };

  const saveInfo = (data) => {
    const save = async () => {
      const info = await userAdd(data, token);
      if (info?.success === true) {
        setOpen(false);
        toast.success("Usuario guardado con exito");
        getInfo();
      } else {
        toast.error("Error al guardar el usuario");
      }
    };
    save();
  };

  const updateInfo = (data) => {
    const save = async () => {
      const info = await userUpdate(data, token);
      if (info?.success === true) {
        setopenUpdate(false);
        toast.success("Usuario actualizado con exito");
        getInfo();
      } else {
        toast.error("Error al actualizar el usuario");
      }
    };
    save();
  };

  const deleteDato = async (id) => {
    const info = await userDelete(
      {
        id,
      },
      token
    );
    if (info?.success === true) {
      toast.success("Usuario eliminado con exito");
      getInfo();
    } else {
      toast.error("Error al eliminar el usuario");
    }
  };

  return (
    <div className="container mt-5">
      {open && <ModalAdd open={open} setOpen={setOpen} saveInfo={saveInfo} />}
      {openUpdate && (
        <ModalUpdate
          open={openUpdate}
          setOpen={setopenUpdate}
          saveInfo={updateInfo}
          data={dataUpdate}
        />
      )}
      <TableComponent
        title="Listado de usuarios"
        data={Data ?? []}
        columns={columns}
        dataGet={dataGet}
        deleteDato={deleteDato}
        openModal={openModal}
        updateData={openModalUpdateData}
      />
    </div>
  );
}
