/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  empleadosGet,
  empleadosAdd,
  empleadosUpdate,
  empleadosDelete,
  empleadoAddContrato,
} from "../../services/empleados";
import { getValues } from "../../reducers/loginReducer";
import TableComponent from "../../components/Table/empleados";
import ModalAdd from "./Modals/ModalAdd";
import { toast } from "react-hot-toast";
import ModalUpdate from "./Modals/ModalUpdate";
import { empleadosAreaGet } from "../../services/empleados";
import ModalContrato from "./Modals/ModalContrato";

const columns = ["Nombre", "Correo", "Telefono", "Area"];
const dataGet = ["nombre", "correo", "telefono", "area"];

export default function Index() {
  const dispatch = useDispatch();
  const { token } = useSelector(getValues);
  const [Data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setopenUpdate] = React.useState(false);
  const [openContrato, setopenContrato] = React.useState(false);
  const [dataUpdate, setDataUpdate] = React.useState({});
  const [Areas, setAreas] = React.useState([]);

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(setTitle("Descargando empleados"));
    dispatch(showLoader());
    getInfo();
    return () => {};
  }, []);

  useEffect(() => {
    empleadosAreaGet(token).then((res) => {
      setAreas(res.data);
    });
  }, []);

  const getInfo = async () => {
    const response = await empleadosGet(token);
    dispatch(hideLoader());
    dispatch(setTitle("Listado de empleados"));
    setData(response.data);
  };

  const openModalUpdateData = (data) => {
    setDataUpdate(data);
    setopenUpdate(true);
  };

  const saveInfo = (data) => {
    const save = async () => {
      const info = await empleadosAdd(data, token);
      if (info?.success === true) {
        setOpen(false);
        toast.success("Empleado guardado con exito");
        getInfo();
      } else {
        toast.error("Error al guardar el Empleado");
      }
    };
    save();
  };

  const updateInfo = (data) => {
    const save = async () => {
      const info = await empleadosUpdate(data, token);
      if (info?.success === true) {
        setopenUpdate(false);
        toast.success("Empleado actualizado con exito");
        getInfo();
      } else {
        toast.error("Error al actualizar el Empleado");
      }
    };
    save();
  };

  const saveContrato = (data) => {
    const save = async () => {
      const info = await empleadoAddContrato(data, token);
      if (info?.success === true) {
        setopenContrato(false);
        toast.success("Contrato guardado con exito");
        getInfo();
      } else {
        toast.error("Error al guardar el Contrato");
      }
    };
    save();
  };

  const deleteDato = async (id) => {
    const info = await empleadosDelete(
      {
        id,
      },
      token
    );
    if (info?.success === true) {
      toast.success("Empleado eliminado con exito");
      getInfo();
    } else {
      toast.error("Error al eliminar el Empleado");
    }
  };

  const createContract = (data) => {
    setDataUpdate(data);
    setopenContrato(true);
  };

  return (
    <div className="container mt-5">
      {open && (
        <ModalAdd
          Areas={Areas}
          open={open}
          setOpen={setOpen}
          saveInfo={saveInfo}
        />
      )}
      {openUpdate && (
        <ModalUpdate
          Areas={Areas}
          open={openUpdate}
          setOpen={setopenUpdate}
          saveInfo={updateInfo}
          data={dataUpdate}
          token={token}
        />
      )}
      {openContrato && (
        <ModalContrato
          open={openContrato}
          setOpen={setopenContrato}
          saveInfo={saveContrato}
          data={dataUpdate}
        />
      )}
      <TableComponent
        title="Listado de empleados"
        data={Data ?? []}
        columns={columns}
        dataGet={dataGet}
        deleteDato={deleteDato}
        openModal={openModal}
        updateData={openModalUpdateData}
        createContract={createContract}
      />
    </div>
  );
}
