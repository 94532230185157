/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { calificacionesEmpleadoGet } from "../../services/calificaciones";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getValues } from "../../reducers/loginReducer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Calificaciones",
    },
  },
};

export default function Graficas() {
  const { id } = useParams();
  const { token } = useSelector(getValues);
  const [empleado, setEmpleado] = React.useState([]);
  const [data, setData] = React.useState({
    labels: [],
    datasets: [
      {
        fill: true,
        label: "Criterios",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  });

  React.useEffect(() => {
    calificacionesEmpleadoGet(id, token).then((res) => {
      const data = res.data.map((item) => item.calificacion);
      // labels recortar a 10 caracteres
      const labels = res.data.map((item) => item.criterio.split(" ").slice(0, 2).join(" "));

      setData({
        labels,
        datasets: [
          {
            fill: true,
            label: "Criterios",
            data,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      });
      setEmpleado(res.data);
    });
    return () => {};
  }, []);

  return (
    <div>
      <h3>Calificaciones {empleado[0]?.empleado ?? ""}</h3>
      {empleado.length > 0 && <Bar data={data} options={options} />}
    </div>
  );
}
