/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Swal from "sweetalert2";
import { login as loginService } from "../../services/login";
import { useDispatch } from "react-redux";
import { setAllValues } from "../../reducers/loginReducer";
import Input from "../../components/Form/Input";

export default function Index() {
  const dispatch = useDispatch();
  const [form, setform] = useState({
    email: "",
    password: "",
  });

  const login = async () => {
    try {
      // swal loading
      Swal.fire({
        title: "Cargando...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      form.email = form.email.trim();
      form.password = form.password.trim();
      const response = await loginService(form);
      if (response.success) {
        Swal.close();
        dispatch(setAllValues(response.data));
        window.location.href = "/dashboard";
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Usuario o contraseña incorrectos",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Usuario o contraseña incorrectos",
      });
    }
  };

  return (
    <section className="vh-100" style={{ backgroundColor: "#363335" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div
              className="card"
              style={{ borderRadius: "1rem", background: "#fff" }}
            >
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src={
                      "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                    }
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-5 p-lg-6 text-black">
                    <form
                      className="col-md-8 mx-auto"
                      onSubmit={(e) => {
                        e.preventDefault();
                        login();
                      }}
                    >
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <i
                          className="fas fa-cubes fa-2x"
                          style={{ color: "#ff6219" }}
                        ></i>
                        <span className="h3 fw-bold mb-0">
                          Inicio de sesión
                        </span>
                      </div>
                      <div className="form-outline mb-4">
                        <Input
                          label={"Correo electronico"}
                          type="email"
                          id="email"
                          className="form-control form-control-lg"
                          value={form.email}
                          name="email"
                          onChange={(e) =>
                            setform({ ...form, email: e.target.value })
                          }
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <Input
                          label={"Contraseña"}
                          type="password"
                          id="password"
                          className="form-control form-control-lg"
                          value={form.password}
                          name="password"
                          onChange={(e) =>
                            setform({ ...form, password: e.target.value })
                          }
                          password={true}
                        />
                      </div>

                      <div className="pt-1 mb-4">
                        <button className="btn btn-dark btn-lg" type="submit">
                          Iniciar sesión
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
