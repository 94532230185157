import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Usuarios from "../pages/usuarios";
import Areas from "../pages/areas";
import Empleados from "../pages/empleados";
import Criterios from "../pages/criterios";
import Calificaciones from "../pages/calificaciones";
import GraficasCalificaciones from "../pages/calificaciones/user";
import PrivateRoute from "./PrivateRoute";

function Root() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/areas" element={<Areas />} />
        <Route path="/empleados" element={<Empleados />} />
        <Route path="/criterios" element={<Criterios />} />
        <Route path="/calificaciones" element={<Calificaciones />} />
        <Route
          path="/calificaciones/:id"
          element={<GraficasCalificaciones />}
        />
      </Route>
      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  );
}

export default Root;
