import axios from "axios";
const APP_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

export const userGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}userGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const userAdd = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}userAdd`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const userUpdate = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}userUpdate`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const userDelete = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}userDelete`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};
