/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Input from "../../../components/Form/Input";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalContrato({ open, setOpen, saveInfo, data }) {
  const [values, setyvalues] = React.useState({
    file_contrato: "",
  });

  const handleClose = () => {
    cleanValues();
    setOpen(false);
  };

  React.useEffect(() => {
    if (data) {
      setyvalues({
        file_contrato: data.file_contrato,
      });
    }
    return () => {};
  }, [data]);

  const save = () => {
    const formData = new FormData();
    formData.append("file_contrato", values.file_contrato);
    formData.append("id", data.id);
    saveInfo(formData);
  };

  const cleanValues = () => {
    setyvalues({
      file_contrato: "",
    });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{
            background: "#fff",
            padding: "0px !important",
          }}
        >
          <Toolbar
            style={{
              background: "#363335",
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Contrato
            </Typography>
            <Button autoFocus color="inherit" onClick={save}>
              Guardar
            </Button>
          </Toolbar>
          <div className="px-5 pb-5 mt-3">
            <Input
              label="Contrato"
              name="file_contrato"
              type="file"
              onChange={(e) => {
                setyvalues({ ...values, file_contrato: e.target.files[0] });
              }}
            />

            {/* poner un iframe */}
            {data && data.file_contrato && (
              <iframe
                src={"http://localhost:8000/storage/" + data.file_contrato}
                title="Contrato"
                width="100%"
                height="500px"
              />
            )}
          </div>
        </AppBar>
      </Dialog>
    </div>
  );
}
