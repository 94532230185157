import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import InputNumber from "../../../components/Form/InputNumber";
import { calificacionesDelete } from "../../../services/calificaciones";
import { toast } from "react-hot-toast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalUpdate({
  open,
  setOpen,
  saveInfo,
  data,
  Criterios,
  Empleados,
  token,
  getInfo
}) {
  const [values, setyvalues] = React.useState({
    empleados_id: "",
    criterios: [],
    fecha: new Date().toISOString().slice(0, 10),
  });

  React.useEffect(() => {
    if (data) {
      setyvalues({
        empleados_id: data.empleado_id ?? "",
        criterios: data.critero ?? [],
        fecha: data.fecha,
      });
    }
    return () => {};
  }, [data]);

  const handleCloseUpdate = () => {
    getInfo();
    cleanValues();
    setOpen(false);
  };

  const save = () => {
    const arraySend = {
      empleados_id: values.empleados_id,
      criterios: values.criterios,
      fecha: values.fecha ?? new Date().toISOString().slice(0, 10)
    };

    saveInfo(arraySend);
  };

  const cleanValues = () => {
    setyvalues({
      empleados_id: "",
      criterios: [],
      fecha: "",
    });
  };

  const eliminar = (id) => {
    calificacionesDelete(
      {
        id: id,
      },
      token
    ).then((res) => {
      if (res.success) {
        const newData = values.criterios.filter((item) => item.id !== id);
        setyvalues({ ...values, criterios: newData });
        toast.success("Eliminado correctamente");
      }
    });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleCloseUpdate}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative" }}
          style={{
            background: "#fff",
            padding: "0px !important",
          }}
        >
          <Toolbar
            style={{
              background: "#363335",
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseUpdate}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Actualizar calificaciones
            </Typography>
            <Button autoFocus color="inherit" onClick={save}>
              Guardar
            </Button>
          </Toolbar>
          <div className="px-5 pb-5 mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Selecciona un empleado
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.empleados_id}
                label="Selecciona un empleado"
                className="mb-3"
                onChange={(e) => {
                  setyvalues({ ...values, empleados_id: e.target.value });
                }}
              >
                {Empleados.map((item) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })}
              </Select>
            </FormControl>

            {/* button add critero y calificacion */}
            <Button
              style={{
                marginBottom: "10px",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                setyvalues({
                  ...values,
                  criterios: [
                    ...values.criterios,
                    {
                      criterios_id: "",
                      calificacion: "",
                    },
                  ],
                });
              }}
            >
              Agregar criterio y calificacion
            </Button>

            {values?.criterios.map((item, index) => {
              return (
                <div className="mt-3 d-flex justify-content-center align-items-center gap-5">
                  <FormControl
                    style={{
                      width: "500px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Selecciona un criterio
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={item.criterios_id}
                      label="Selecciona un criterio"
                      className="mb-3"
                      onChange={(e) => {
                        const newCriterios = values.criterios;
                        newCriterios[index].criterios_id = e.target.value;
                        setyvalues({ ...values, criterios: newCriterios });
                      }}
                    >
                      {Criterios.map((item) => {
                        return (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <InputNumber
                    label="Calificacion"
                    type="number"
                    value={item.calificacion}
                    onChange={(e) => {
                      const newCriterios = values.criterios;
                      newCriterios[index].calificacion = e.target.value;
                      setyvalues({ ...values, criterios: newCriterios });
                    }}
                  />

                  <Button
                    style={{
                      marginTop: "-20px",
                    }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      eliminar(item.id);
                    }}
                  >
                    Eliminar
                  </Button>

                  <hr />
                </div>
              );
            })}
          </div>
        </AppBar>
      </Dialog>
    </div>
  );
}
