import axios from "axios";
const APP_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

export const calificacionesEmpleadosGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}calificacionesEmpleadosGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesCriteriosGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}calificacionesCriteriosGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesEmpleadoGet = async (id, token) => {
  try {
    const res = await axios.get(`${APP_URL}calificacionesEmpleadoGet/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesAreasGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}calificacionesAreasGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}calificacionesGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesAdd = async (data, token) => {
  try {
    const res = await axios.post(
      `${APP_URL}calificacionesAdd`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesUpdate = async (data, token) => {
  try {
    const res = await axios.post(
      `${APP_URL}calificacionesUpdate`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const calificacionesDelete = async (data, token) => {
  try {
    const res = await axios.post(
      `${APP_URL}calificacionesDelete`,
      JSON.stringify(data),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};
