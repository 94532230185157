import axios from 'axios';
const APP_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api/';

export const login = async (data) =>{
    try {
        const res = await axios.post(`${APP_URL}auth/login`, JSON.stringify(data));
        return res.data;
    } catch (err) {
        return console.log(err);
    }
}