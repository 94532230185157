import { TextField } from "@mui/material";
import React from "react";

export default function InputNumber({
  label,
  name,
  value,
  onChange,
  type
}) {
  return (
    <TextField
      style={{
        width: "500px",
      }}
      label={label}
      variant="outlined"
      value={value}
      name={name}
      onChange={onChange}
      className="mb-3"
      type={type}
    />
  );
}
