import axios from "axios";
const APP_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api/";

export const criteriosGet = async (token) => {
  try {
    const res = await axios.get(`${APP_URL}criteriosGet`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const criteriosAdd = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}criteriosAdd`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const criteriosUpdate = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}criteriosUpdate`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};

export const criteriosDelete = async (data, token) => {
  try {
    const res = await axios.post(`${APP_URL}criteriosDelete`, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return console.log(err);
  }
};
